<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
            color="white"
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoy
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ (typeToLabel[type] == "Month")? "Mes" : "Día" }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <agenda-component
              v-if="dialogAgenda"
              :id="id"
              :cliente="cliente"
              :client="client"
              :visible="dialogAgenda"
              @close="dialogAgenda = false"
            />
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            dark
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          />
          <!-- @change="updateRange" -->
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-btn
                  icon
                  @click="showAgenda"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name" />
                <v-spacer />
                <!-- <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> -->
              </v-toolbar>
              <v-card-text>
                Inicio: <span v-html="selectedEvent.start" /><br>
                Fin: <span v-html="selectedEvent.end" /><br>
                Tiempo Sala: <span v-html="selectedEvent.tiempoSala" /><br>
                Tiempo Notario: <span v-html="selectedEvent.tiempoNotario" /><br>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AgendaApi from '@/services/api/Agenda'
  import EventBus from '../../../bus'

  export default {
    name: 'AdminAgenda',

    components: {
      AgendaComponent: () => import('@/views/dashboard/component/Agenda'),
    },

    data: () => ({
      valid: true,
      snackbar: false,
      colorSnak: 'info',
      text: '',
      timeout: 3000,
      lazy: false,
      dialog: false,
      dialogAgenda: false,
      id: '',
      cliente: '',
      client: true,
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        day: 'Day',
      },
      intervals: {
        first: 16,
        minutes: 30,
        count: 20,
        height: 48,
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue'],
      // names: ['Compraventa', 'Herencia', 'Constitución Sociedades', 'Capitulaciones', 'Testamento', 'Poderes'],
      editedItem: {
        id: '',
      },
      defaultItem: {
        id: '',
      },
    }),
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    created () {
      this.initialize()
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      initialize () {
        this.findCitasByCliente()
        EventBus.$on('update-agenda', () => {
          this.events = []
          this.findCitasByCliente()
        })
      },
      findCitasByCliente () {
        AgendaApi.getCitasClienteByEmail()
          .then(response => {
            this.editedItem.id = response.id
            this.cliente = response[0].cliente
            this.id = response[0].cliente
            this.updateEvents(response)
          })
          .catch(error => {
            console.log(error)
          })
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      showAgenda () {
        if (this.dialogAgenda) {
          this.dialogAgenda = false
        } else {
          this.dialogAgenda = true
        }
      },
      updateEvents (citas) {
        const events = []

        for (let i = 0; i < citas.length; i++) {
          const first = String(citas[i].inicio.date).substring(0, 16)
          const second = String(citas[i].fin.date).substring(0, 16)
          events.push({
            name: citas[i].procedimiento,
            start: first,
            end: second,
            tiempoSala: citas[i].tiempo_sala,
            tiempoNotario: citas[i].tiempo_notario,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          // this.editedItem = Object.assign({}, this.defaultItem)
          // this.editedIndex = -1
        }, 300)
      },
    },
  }
</script>
